import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  useEffect(() => {
    setTimeout(() => {
      disappearId('load1');
    }, 1000);
    setTimeout(() => {
      disappearId('intermission1');
    }, 2500);   
    setTimeout(() => {
      disappearId('load2');
    }, 3500); 
    setTimeout(() => {
      disappearId('intermission2');
    }, 4500); 
    setTimeout(() => {
      disappearId('load3');
    }, 5000); 
  });

  function disappearId(id_name) {
    if (Array.isArray(id_name)) {
      for (let i = 0; i < id_name.length; ++i) {
        document.getElementById(id_name[i]).style.display = 'none';
      }
    }
    else {
      document.getElementById(id_name).style.display = 'none';
    }
  };

  function appearId(id_name) {
    document.getElementById(id_name).style.display = 'block';
  }

  return (
    <div className="App">
      <header className="App-header">
        <img id="backgroundimage" src="desktop.png" />
        <img class="loader" id="load1" src="loader1.png" />
        <img class="loader" id="intermission1" src="intermission1.png" />
        <img class="loader" id="load2" src="loader2.png" />
        <img class="loader" id="intermission2" src="intermission2.png" />
        <img class="loader" id="load3" src="loader3.png" />

        <div class="loader" id="qbox1">
          <div class="button-box">
            <button onClick={() => disappearId('qbox1')} class="response-button"><img class="button-image" src="yesbutton.png"/></button>
            <button onClick={() => disappearId(['qbox1', 'qbox2'])} class="response-button"><img class="button-image" src="nobutton.png"/></button>
          </div>
          <img class="loadimage" src="qbox1.png" />
        </div>

        <div class="loader" id="qbox2">
          <div class="button-box">
            <a href="mailto:draftdodgers2025@gmail.com" class="response-button"><img class="button-image" src="yesbutton.png"/></a>
            <button onClick={() => disappearId(['qbox2', 'qbox3'])} class="response-button"><img class="button-image" src="nobutton.png"/></button>
          </div>
          <img class="loadimage" src="qbox2.png" />
        </div>

        <div class="loader" id="qbox3">
          <div class="button-box">
            <button onClick={() => appearId('movieposterdiv')} class="response-button"><img class="button-image" src="business.png"/></button>
            <button onClick={() => appearId('curlvideodiv')} class="response-button"><img class="button-image" src="pleasure.png"/></button>
          </div>
          <img class="loadimage" src="qbox3.png" />
        </div>

        <div class="loader" id="qbox4">
          <img class="loadimage" src="qbox4.png" />
          <div class="button-box inframeback">
            <button class="inframebackbutton" onClick={() => appearId('qbox2')}>back</button>
          </div>
        </div>

        <div onClick={() => appearId('actualposterdiv')} id='movieposterdiv'>
          <img id='movieposter' src="movieposter.png"/>
        </div>

        <div id='spotifydiv'>
          <a href="https://open.spotify.com/playlist/5lq0me1DYBXvSNCUG3qdaA?si=25dc342036624674" ><img id='spotify' src="spotify.png"/></a>
        </div>

        <div id='actualposterdiv'>
          <img id='actualposter' src='finalposter.png'/>
          <button id='closeoutbutton' onClick={() => disappearId('actualposterdiv')}>back</button>
        </div>

        <div id='curlvideodiv'>
          <iframe id='curlvideo' src="https://www.youtube.com/embed/FOG0Yp59SU0" /> <br/>
          <button id='closeoutbutton' onClick={() => disappearId('curlvideodiv')}>back</button>
        </div>
        
      </header>
    </div>
  );
}

export default App;
